@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;700&display=swap');
@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf');
  /* font-weight: 500; */
  font-style: normal;
}

@font-face {
  font-family: 'TenorSans';
  src: url('./assets/fonts/TenorSans-Regular.ttf');
  font-style: normal;
}
@font-face {
  font-family: 'Baloo2';
  src: url('./assets/fonts/Baloo2-VariableFont_wght.ttf');
  /* font-weight: 500; */
  font-style: normal;
}
html {
  scroll-behavior: smooth;
}
.background-gradient {
  background: linear-gradient(45deg, #989ed2, #c2b1d0, #a3b3d4);
}
